import { useState, useEffect } from 'react';
import { formatEther, parseEther } from "ethers";
import { Row, Col, Card, Button, Form, Spinner } from 'react-bootstrap';
import { pinata } from '../config';
import NFTAddress from '../contractsData/NFT-address.json';
import MarketplaceAddress from '../contractsData/Marketplace-address.json'

export default function MyPurchases({ marketplace, nft, account }) {
  const [loading, setLoading] = useState(true);
  const [purchases, setPurchases] = useState([]);
  const [listingPrice, setListingPrice] = useState({}); // State for listing price input

  const loadPurchasedItems = async () => {
    try{

      const itemCount = Number(await nft?.tokenCount());
      const ownedItems = [];
  
      for (let i = 1; i <= itemCount; i++) {
        const owner = await nft.ownerOf(i);
        if (owner.toLowerCase() === account.toLowerCase()) {
          const uri = await nft.tokenURI(i);
          const ipfsHash = uri.replace('ipfs://', '');
          const metadata = (await pinata.gateways.get(ipfsHash)).data;
          const totalPrice = await marketplace.getTotalPrice(i);
  
          let nftItem = {
            totalPrice,
            tokenId: i,
            name: metadata.name,
            description: metadata.description,
            image: metadata.image,
            rarity: metadata.rarity,
            condition: metadata.condition,
            roi: metadata.roi,
          };
          ownedItems.push(nftItem);
        }
      }
      setPurchases(ownedItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching purchased items: ", error);
      setLoading(false);
    }
  };

  const listNFT = async (tokenId) => {
    try {
      const price = parseEther(listingPrice);
      console.log("Listing NFT with price: ", price);
      const isApproved = await nft.isApprovedForAll(account, MarketplaceAddress.address);
      console.log("isApproved: ", isApproved);

      if (!isApproved) {
        const approveTx = await nft.setApprovalForAll(MarketplaceAddress.address, true);
        await approveTx.wait();
      }

      const tx = await marketplace.makeItem(NFTAddress.address, tokenId, price);
      console.log("Listing NFT tx: ", tx);
      await tx.wait();
      alert(`NFT with Token ID ${tokenId} listed successfully!`);
      loadPurchasedItems();
    } catch (error) {
      console.error("Error listing NFT: ", error);
      alert(`Failed to list NFT with Token ID ${tokenId}.`);
    }
  };

  useEffect(() => {
    loadPurchasedItems();
  }, []);

  if (loading) return (
    <div className="loading-container">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      {/* <h3>Loading...</h3> */}
    </div>
  );

  return (
    <div className="flex justify-center">
      <div className="container px-5 py-5">
        {purchases.length > 0 ? (
          <>
            <h2 className="text-center mb-4">My NFTs</h2>
            <Row xs={1} md={2} lg={3} className="g-4">
              {purchases.map((item, idx) => (
                <Col key={idx}>
                  <Card className="nft-card">
                    <Card.Img variant="top" src={`https://ipfs.io/ipfs/${item?.image.replace('ipfs://', '')}`} className="card-img-top" />
                    <Card.Body className="d-flex flex-column">
                      <Card.Title className="mb-3">{item.name}</Card.Title>
                      <Card.Text className="text-muted">{item.description}</Card.Text>
                      <Card.Text className="text-muted">{item.rarity}</Card.Text>
                      <Card.Text className="text-muted">{item.condition}</Card.Text>
                      <Card.Text className="text-muted">{item.roi}</Card.Text>
                      <Card.Text className="">Price {formatEther(item.totalPrice)} $MNT</Card.Text>
                      <Form className="mt-auto">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="number"
                            placeholder="Listing Price"
                            onChange={(e) => setListingPrice(e.target.value)}
                          />
                        </Form.Group>
                        <Button
                          variant="primary"
                          onClick={() => listNFT(item.tokenId)}
                          className="w-100"
                        >
                          List for Sale
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <div className="text-center">
            <h2>No NFTs Purchased</h2>
          </div>
        )}
      </div>
    </div>
  );
}
