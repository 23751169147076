import { PinataSDK } from "pinata-web3";
// import { createAppKit } from '@reown/appkit/react'
// import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'

export const pinata = new PinataSDK({
  pinataJwt: process.env.REACT_APP_PINATA_JWT,
  pinataGateway: process.env.REACT_APP_GATEWAY_URL,
  pinataGatewayKey: process.env.REACT_APP_GATEWAY_TOKEN
});


// 1. Get projectId
const projectId = process.env.REACT_APP_PROJECT_ID

// 2. Set Ethers adapters
// const ethers5Adapter = new Ethers5Adapter()
export const mantle = {
    chainId: 5000,
    name: 'Mantle',
    currency: 'MNT',
    explorerUrl: 'https://explorer.mantle.xyz',
    rpcUrl: 'https://rpc.mantle.xyz',
    imageUrl: '',
    imageId: '',
};
// 3. Create a metadata object - optional
export const metadata = {
  name: 'Pokechain NFT',
  description: 'Your nft marketplace',
  url: 'https://nft.pokechain.io',
  icons: ['https://nft.pokechain.io/']
}
