import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Navigation from './Navbar';
import Home from './Home.js'
import Create from './Create.js'
import AdminPage from './AdminHome.js'
import MyPurchases from './MyPurchases.js'
import MarketplaceAbi from '../contractsData/Marketplace.json'
import MarketplaceAddress from '../contractsData/Marketplace-address.json'
import NFTAbi from '../contractsData/NFT.json'
import NFTAddress from '../contractsData/NFT-address.json'
import { useState, useEffect } from 'react'
import { BrowserProvider, Contract, getAddress } from "ethers"
import { Spinner } from 'react-bootstrap'
import { mantle, metadata } from "../config.js";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';

import './App.css';

function App() {
  const [loading, setLoading] = useState(false)
  const [account, setAccount] = useState(null)
  const [nft, setNft] = useState({})
  const [marketplace, setMarketplace] = useState({})
  const [isAdmin, setIsAdmin] = useState(false)
  const [web3Modal, setWeb3Modal] = useState(null);


 const connectWallet = async () => {
  try {
    console.log("Opening Web3Modal...");
    
    // Open the modal
    await web3Modal.open();
    console.log("Web3Modal opened successfully");

    // Wait for connection (you might need to adjust the timing here)
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Get the address
    const address = web3Modal.getAddress();
    console.log("Connected address:", address);

    if (address) {
      setAccount(address);
      
      // Get the provider
      const walletProvider = web3Modal.getWalletProvider();
      console.log("Provider:", walletProvider);
      const provider = new BrowserProvider(walletProvider);
      console.log("BrowserProvider created");

      const signer = await provider.getSigner();
      console.log("Signer obtained", signer);

      await loadContracts(signer, address);
      console.log("Contracts loaded");
    } else {
      console.log("No address found after connection");
    }

    // Set up subscriptions
    web3Modal.subscribeProvider((newState) => {
      console.log("Provider state changed:", newState);
      const newAddress = web3Modal.getAddress();
      if (newAddress) {
        setAccount(newAddress);
        const provider = new BrowserProvider(web3Modal.getWalletProvider());
        console.log('provider: ', provider);
        provider.getSigner().then(signer => {

          loadContracts(signer, newAddress);
        });
      } else {
        setAccount(null);
        setNft({});
        setMarketplace({});
        setIsAdmin(false);
      }
    });

    web3Modal.subscribeCaipNetworkChange((newNetwork) => {
      console.log("Network changed:", newNetwork);
      // You might want to handle network changes here
      // For example, reload the page or update the contracts
    });

  } catch (error) {
    console.error("Failed to connect wallet:", error);
  } finally {
    setLoading(false);
  }
};

  const loadContracts = async (signer, account) => {
    try{
        const nft = new Contract(NFTAddress.address, NFTAbi.abi, signer)
        const market = new Contract(MarketplaceAddress.address, MarketplaceAbi.abi, signer)
        setNft(nft)
        const admin = await nft.admin()
        const feeReceiver = await market.feeAccount()
        console.log("Fee Receiver: ", feeReceiver)
        setIsAdmin(getAddress(admin) === getAddress(account));

        console.log("NFT: ", nft)
        setMarketplace(market)
        setLoading(false)
    } catch (err) {
        console.log(err)
    }
  }


  useEffect(() => {
    const initWeb3Modal = async () => {
      const web3modalInstance = createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [mantle],
        projectId: process.env.REACT_APP_PROJECT_ID,
      });
      setWeb3Modal(web3modalInstance);
    };

    initWeb3Modal();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <>
          <Navigation web3Handler={connectWallet} account={account} Admin={isAdmin} />
        </>
        <div>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
              <Spinner animation="border" style={{ display: 'flex' }} />
              <p className='mx-3 my-0'>Awaiting Metamask Connection...</p>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={
                <Home nft={nft} marketplace={marketplace} isAdmin={isAdmin} />
              } />
              <Route path="/create" element={
                <Create nft={nft} />
              } />
              {/* <Route path="/create" element={
                <Create nft={nft} />
              } /> */}
              <Route path="/my-purchases" element={
                <MyPurchases nft={nft} marketplace={marketplace} account={account} />
              } />
              <Route path="/admin" element={
                <AdminPage nft={nft} isAdmin={isAdmin} market={marketplace} account={account} />
              } /> 
            </Routes>
          )}
        </div>
      </div>
    </BrowserRouter>

  );
}

export default App;
