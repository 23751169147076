import { useState, useEffect, useCallback } from 'react'
import { Row, Col, Card, FormControl, Button, Spinner } from 'react-bootstrap'
import { Contract, formatEther, JsonRpcProvider } from 'ethers'
import MarketplaceAbi from '../contractsData/Marketplace.json'
import MarketplaceAddress from '../contractsData/Marketplace-address.json'
import NFTAbi from '../contractsData/NFT.json'
import NFTAddress from '../contractsData/NFT-address.json'
import { pinata } from '../config'


const Home = ({ marketplace, nft, isAdmin }) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredItems, setFilteredItems] = useState([])

  const loadMarketplaceItems = useCallback( async () => {
  try {
    let provider;
    provider = new JsonRpcProvider("https://mantle-mainnet.infura.io/v3/e8722818280b4236a23302a9cdbedcb2", {
        name: 'Mantle',
        chainId: 5000,
      });

    const market = new Contract(MarketplaceAddress.address, MarketplaceAbi.abi, provider);
    const readNft = new Contract(NFTAddress.address, NFTAbi.abi, provider);


    const itemCount = Number(await market.itemCount());
    if (itemCount <= 0) {
      console.error("No items found");
      setLoading(false);
      return;
    }

    let loopItems = [];
    for (let i = 1; i <= itemCount; i++) {
      const item = await market.items(i);

      // Skip sold items
      if (item.sold) continue;

      const tokenId = item.tokenId;
      const price = formatEther(item.price);
      const seller = item.seller;
      const uri = await readNft.tokenURI(tokenId);

      // Fetch NFT metadata from IPFS
      const ipfsHash = uri.replace('ipfs://', '');
      const metadata = (await pinata.gateways.get(ipfsHash)).data;


      // Add item to the items array
      loopItems.push({
        itemId: item.itemId,
        price,
        seller,
        name: metadata.name,
        description: metadata.description,
        rarity: metadata.rarity,
        condition: metadata.condition,
        roi: metadata.roi,
        image: `https://ipfs.io/ipfs/${metadata?.image.replace('ipfs://', '')}`,
      });
    }
    

    setItems(loopItems);
    setFilteredItems(loopItems);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching items: ", error);
    setLoading(false);
  }
},
[]);


  // Function to handle purchasing an NFT
const buyItem = async (item) => {
  if (!marketplace) {
    console.error("Marketplace contract not initialized");
    alert("Please connect your wallet and try again.");
    return;
  }

  try {
    console.log("Initiating purchase for item:", item);

    // Get the total price
    const totalPrice = await marketplace.getTotalPrice(item.itemId);
    console.log("Total price:", totalPrice.toString());

    // Initiate the transaction
    const transaction = await marketplace.purchaseItem(item.itemId, { value: totalPrice });
    console.log("Transaction initiated:", transaction.hash);

    // Wait for the transaction to be mined
    const receipt = await transaction.wait();
    console.log("Transaction confirmed:", receipt.transactionHash);

    // Check if the transaction was successful
    if (receipt.status === 1) {
      alert("Purchase successful!");
      await loadMarketplaceItems(); // Reload items after purchase
    } else {
      alert("Purchase failed. Please check your transaction on the blockchain explorer.");
    }

  } catch (error) {
    console.error("Purchase failed:", error);
    if (error.code === 'ACTION_REJECTED') {
      alert("Transaction rejected by user.");
    } else if (error.code === 'INSUFFICIENT_FUNDS') {
      alert("Insufficient funds to complete the purchase.");
    } else {
      alert(`An error occurred: Make sure you have sufficient balance to purchase the item.`);
    }
  } finally {
    setLoading(false);
  }
};

  useEffect(() => {
    loadMarketplaceItems()
  }, [loadMarketplaceItems])

  // Filter items based on the search query
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredItems(items) // If no search query, show all items
    } else {
      const lowercasedQuery = searchQuery.toLowerCase()
      const filtered = items.filter(item => 
        item.name.toLowerCase().includes(lowercasedQuery) ||
        item.description.toLowerCase().includes(lowercasedQuery) ||
        item.rarity.toLowerCase().includes(lowercasedQuery)
      )
      setFilteredItems(filtered)
    }
  }, [searchQuery, items])

  if (loading) return (
    <div className="d-flex justify-content-center py-3">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )

  return (
    <div className="flex justify-center">
      <div className="d-flex justify-content-center py-3">
          <FormControl
            type="text"
            placeholder="Search NFTs by name, description, rarity..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: '50%' }} // Adjust the width of the search bar
          />
      </div>
      <div className="d-flex justify-content-center py-3">
      {filteredItems.length > 0 ?
        <div className="px-5 container">
          <Row xs={1} md={2} lg={4} className="g-4 py-4">
            {filteredItems.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">
                <Card className='nft-card'>
                  <Card.Img variant="top" width={10} height={150} src={item.image} />
                  <Card.Body color="secondary">
                    <Card.Title>Metadata</Card.Title>
                    <hr />
                    <Card.Text>Name: {item.name}</Card.Text>
                    <Card.Text>
                      Description: {item.description}
                    </Card.Text>
                    <Card.Text>Condition: {item.condition}</Card.Text>
                    <Card.Text>Rarity: {item.rarity}</Card.Text>
                    <Card.Text>Roi: {item.roi}</Card.Text>
                    <Card.Text>Price: {item.price} MNT</Card.Text>
                    <Card.Footer>
                      <Button variant="primary" onClick={() => buyItem(item)}>
                        Buy {item.price} $MNT
                      </Button>
                    </Card.Footer>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        : (
          <main style={{ padding: "1rem 0" }}>
            <h2>No listed assets</h2>
          </main>
        )}
      </div>
    </div>
  );
}

export default Home
